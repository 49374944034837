<template>
  <div>
    <div v-if="restricted" class="restrict-main">
      <img class="hand" :src="hand" alt="hand" />
      <div class="bold">Not so fast...</div>
      <div class="restrict-explanation">
        Let’s take this one step at a time! Complete the preceding section of
        tasks, then come back here to continue your Journey.
      </div>
      <div @click="emit('tabClick', path, '')" class="restrict-button">
        {{ unfinished }}
      </div>
    </div>
    <section v-if="!restricted">
      <div class="divider" />
      <div v-if="showCompleteModal" class="uncheck-modal">
        <div>
          <div class="bold">Nice!</div>
          <div class="exlpanation">
            You’re one step closer to completing your onboarding material and
            finishing your house! Keep an eye on those notifications for updates
            from your leader.
          </div>
        </div>
        <div class="button-area">
          <div class="submit" @click="showCompleteModal = false">Continue</div>
        </div>
      </div>
      <Help
        :tasks="allTasks"
        v-if="showModal"
        @changeStatus="(selected, explanation) => {handleAssistance(selected, explanation)}"
        @showModal="(i) => (showModal = i)"
        
        class="assistance-modal"
      />
      <div v-if="uncheckModal" class="uncheck-modal">
        <div>
          <div class="bold">Are you sure?</div>
          <div class="exlpanation">
            Unchecking a task affects your progress and notifies your trainer.
          </div>
        </div>
        <div class="button-area">
          <div class="back" @click="uncheckModal = false">Cancel</div>
          <div class="submit" @click="handleSubmit()">Submit</div>
        </div>
      </div>
      <div class="months-blocks">
        <div class="month-block">
          <div class="box-title-month">
            <span class="month-number-text">{{ currentMonth }}</span>
          </div>
          <div class="progress-area">
            <div class="month-number">
              <div>{{ monthPercentDone }}</div>
              <div class="bar">
                <div class="bar-outer">
                  <div
                    :style="{
                      width: monthPercentDone,
                      background:
                        'linear-gradient(268.99deg, #D1FCCA -9.05%, #ADE9F6 94.82%)',
                      height: '10px',
                      'border-radius': '20px',
                    }"
                  ></div>
                </div>
                <div class="completed">
                  {{ monthCompleted }}/{{ monthTasks.length }} courses completed
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="task-area">
          <div class="task-block">
            <div class="workday">
              <div>Be sure to use Workday as your course guide!</div>
              <div @click="openExternal()" class="workday-button">
                Go to Workday
              </div>
            </div>
            <!-- <TaskStatusedItem
              v-for="task in courses[0].tasks"
              :key="task.name"
              :name="task.title"
              :status="task.status"
              :disabled="Math.random() > 0.5"
              @click="taskClicked(task)"
            /> -->
            <!-- <div class="task" v-for="task in courses[0].tasks" :key="task.number"> -->
            <div class="task-columns">
              <div class="tasks">
                <CourseItem
                  v-for="(task, index) in monthTasks.slice(
                    0,
                    monthTasks.length / 2
                  )"
                  :key="task.name"
                  :name="task.name"
                  :status="task.status"
                  :number="task.number"
                  :index="index"
                  :task="task"
                  class="tasks"
                  @changeStatus="(task, i) => updateStatus(task, i, 'month')"
                />
              </div>
              <div class="tasks">
                <CourseItem
                  v-for="(task, index) in monthTasks.slice(
                    monthTasks.length / 2
                  )"
                  :key="task.name"
                  :name="task.name"
                  :status="task.status"
                  :number="task.number"
                  :task="task"
                  :index="index + monthTasks.length / 2"
                  class="tasks"
                  @changeStatus="(task, i) => updateStatus(task, i, 'month')"
                />
              </div>
            </div>
          </div>
          <div class="task-block">
            <div class="task-area">
              <div class="engagement-block">
                <div class="box-title-engagement">
                  <span class="month-number-text">Engagement</span>
                </div>
                <div class="progress-area">
                  <div class="month-number">
                    <div>{{ engagementPercentDone }}</div>
                    <div class="bar">
                      <div class="bar-outer">
                        <div
                          :style="{
                            width: engagementPercentDone,
                            background:
                              'linear-gradient(268.99deg, #D1FCCA -9.05%, #ADE9F6 94.82%)',
                            height: '10px',
                            'border-radius': '20px',
                          }"
                        ></div>
                      </div>
                      <div class="completed">
                        {{ engagementCompleted }}/{{
                          engagementTasks.length
                        }}
                        tasks complete
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <TaskStatusedItem
              v-for="task in monthTasks"
              :key="task.name"
              :name="task.title"
              :status="task.status"
              :disabled="Math.random() > 0.5"
              @click="taskClicked(task)"
            /> -->
              <!-- <div class="task" v-for="task in courses[0].tasks" :key="task.number"> -->
              <div class="task-columns">
                <div class="tasks">
                  <CourseItem
                    v-for="(task, index) in engagementTasks.slice(
                      0,
                      engagementTasks.length / 2
                    )"
                    :key="task.name"
                    :name="task.name"
                    :status="task.status"
                    :number="task.number"
                    :task="task"
                    :index="index"
                    class="tasks"
                    @changeStatus="
                      (task, i) => updateStatus(task, i, 'engagement')
                    "
                  />
                </div>
                <div class="tasks">
                  <CourseItem
                    v-for="(task, index) in engagementTasks.slice(
                      engagementTasks.length / 2
                    )"
                    :key="task.name"
                    :name="task.name"
                    :status="task.status"
                    :number="task.number"
                    :task="task"
                    :index="index + engagementTasks.length / 2"
                    class="tasks"
                    @changeStatus="
                      (task, i) => updateStatus(task, i, 'engagement')
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AssistanceButton v-if="showAssistanceButton" @click="showModal = true" />
      <!-- <StatusKeyHelper /> -->
    </section>
  </div>
</template>

<script setup>
import CircularProgress from "@/components/CircularProgress";
import StatusKeyHelper from "@/components/StatusKeyHelper";
import splitTasksArrayByHalfs from "@/utils/splitTasksArrayByHalfs";
import CourseItem from "@/components/CourseItem";
import Help from "@/components/Help";
import AssistanceButton from "@/components/AssistanceButton";
import { onMounted, ref, defineEmits } from "vue";
import { useRouter } from "vue-router";
import ChecklistAPI from "@/api/ChecklistAPI";
import hand from "@/assets/images/hand.png";
import { useStore } from 'vuex'
const store = useStore()
const allTasks = ref();
const router = useRouter();
const monthCompleted = ref(0);
const engagementCompleted = ref(0);
const showModal = ref(false);
const monthPercentDone = ref("0%");
const engagementPercentDone = ref("0%");
const uncheckModal = ref(false);
const currentMonth = ref(null);
const index = ref(null);
const showCompleteModal = ref(false);
const currentTask = ref();
const showAssistanceButton = ref(true)
const restricted = ref(false);
const path = ref();

const emit = defineEmits({
  tabClick: (path) => typeof path === "string",
  signOut: () => true,
});
const handleAssistance = async (tasks, explanation)=>{
  const ids = []
  tasks.forEach(async task=>{
    ids.push(task.id)
    const update = {
      task_id: task.id,
      status: 'attention-required',
      subtask_status: 'unavailable'
    }
   await ChecklistAPI.updateTask(update)
    ids.push(task.id)
  })
  let expl
  if(explanation) expl = explanation
  else expl = 'n/a'
  const payload = {
    problem: expl,
    status: 'active',
    subtask_ids: [],
    task_ids: ids
  }
  await ChecklistAPI.updateAssistance(payload)
}
const unfinished = ref();
const updateStatus = async (task, i, month) => {
  currentTask.value = task;
  if (task.status === "in-review") {
    uncheckModal.value = true;
  } else if (task.status !== "completed") {
    const updatedTask = {
      task_id: task.id,
      status: "in-review",
      subtask_status: 'unavailable'
    };
    await ChecklistAPI.updateTask(updatedTask);
    await getTasks();
    if (
      currentMonth.value === "Month 3 Courses" &&
      allTasks.value.length ===
        month3Completed.value + engagementCompleted.value
    )
      showCompleteModal.value = true;
  }
  //   currentMonth.value = month
  //   if (task.status === 'available' || task.status === 'attention-required') {
  //     console.log(month)
  //     if (month === 'month') {
  //       monthCompleted.value++
  //       monthPercentDone.value = `${Math.floor(monthCompleted.value / monthTasks.value.length * 100)}%`
  //     } else {
  //       engagementCompleted.value++
  //       engagementPercentDone.value = `${Math.floor(engagementCompleted.value / engagementTasks.value.length * 100)}%`
  //     }
  //     if (monthCompleted.value === monthTasks.value.length && engagementCompleted.value === engagementTasks.value.length) showCompleteModal.value = true
  //   }
  //   } else if (task.status !== 'completed') task.status = 'in-review'
  //   courses.value[0].tasks[i] = task
};
const openExternal = () => {
  if(store.state.user.role==='AISI') window.open("https://wd5.myworkday.com/homedepot/learning/program/04dc71cb3045101230b602d92ab80000?type=2d29754fdb8e100008b50ff6bc94003b", "_blank");
  if(store.state.user.role==='AISE') window.open("https://wd5.myworkday.com/homedepot/learning/program/01462f348844101235a9d7b870eb0000?type=2d29754fdb8e100008b50ff6bc94003b", "_blank");
};
const handleSubmit = async () => {
    showAssistanceButton.value = true

  const updatedTask = {
    task_id: currentTask.value.id,
    status: "available",
    subtask_status: 'unavailable'
  };
  await ChecklistAPI.updateTask(updatedTask);

  // await ChecklistAPI.updateTask(currentTask.value)
  await getTasks();
  uncheckModal.value = false;
};
const month1Completed = ref(0);
const month2Completed = ref(0);
const month3Completed = ref(0);

onMounted(async () => {
  const checklist = await ChecklistAPI.getChecklist();
  const workdayList = checklist.find((task) => task.name === "Workday");
  const badgingList = checklist.find((task) => task.name === "Badging");
  const allHR = workdayList.tasks.concat(badgingList.tasks);

  const technologyList = checklist.find((task) => task.name === "Technology");
  const logisticsList = checklist.find((task) => task.name === "Logistics");
  const allSetting = technologyList.tasks.concat(logisticsList.tasks);
  allHR.forEach((item) => {
    if (!item.progresses) {
      console.log("here1");
      restricted.value = true;
      path.value = "/hr";
      unfinished.value = "HR";
    } else if (
      item.progresses[0].status !== "completed" &&
      item.progresses[0].status !== "in-review"
    ) {
      console.log("here2");
      restricted.value = true;
      path.value = "/hr";
      unfinished.value = "HR";
    }
  });
  if (!restricted.value) {
    allSetting.forEach((item) => {
      if (!item.progresses) {
        console.log("here3");
        restricted.value = true;
        path.value = "/setting-up";
        unfinished.value = "Setting Up";
      } else if (
        item.progresses[0].status !== "completed" &&
        item.progresses[0].status !== "in-review"
      ) {
        console.log("here4");
        restricted.value = true;
        path.value = "/setting-up";
        unfinished.value = "Setting Up";
      }
    });
  }
  await getTasks();
});
const monthTasks = ref();
const engagementTasks = ref();

const getTasks = async () => {
  const checklist = await ChecklistAPI.getChecklist();
  //  const workdayList =  checklist.find(task => task.name ==='Workday')
  //    const badgingList =  checklist.find(task => task.name ==='Badging')
  //    const allHR = workdayList.tasks.concat(badgingList.tasks)
  //    allHR.forEach(item=>{
  //       if(!item.progresses){
  //         console.log('here1')
  //        restricted.value = true
  //        path.value = '/hr'
  //        unfinished.value='HR'
  //      }
  //      else if(item.progresses[0].status !== 'completed' && item.progresses[0].status !== 'in-review') {
  //         console.log('here2')
  //        restricted.value = true
  //        path.value = '/hr'
  //         unfinished.value='HR'
  //        }
  //    })
  // const technologyList =  checklist.find(task => task.name ==='Technology')
  //    const logisticsList =  checklist.find(task => task.name ==='Logistics')
  //    const allSetting = technologyList.tasks.concat(logisticsList.tasks)
  //     allSetting.forEach(item=>{
  //      if(!item.progresses){
  //         console.log('here3')
  //        restricted.value = true
  //        path.value = '/setting-up'
  //       unfinished.value='Setting Up'
  //      }
  //      else
  //      if(item.progresses[0].status !== 'completed' && item.progresses[0].status !== 'in-review'){
  //         console.log('here4')
  //      restricted.value = true
  //      path.value = '/setting-up'
  //     unfinished.value='Setting Up'
  //      }
  //    })
  const month1List = checklist.find((task) => task.name === "Month 1 Courses");
  month1List.tasks.sort(function (a, b) {
    return a.order - b.order;
  });
  const month2List = checklist.find((task) => task.name === "Month 2 Courses");
  month2List.tasks.sort(function (a, b) {
    return a.order - b.order;
  });
  const month3List = checklist.find((task) => task.name === "Month 3 Courses");
  month3List.tasks.sort(function (a, b) {
    return a.order - b.order;
  });
  const engagementList = checklist.find((task) => task.name === "Engagement");
  engagementList.tasks.sort(function (a, b) {
    return a.order - b.order;
  });
  console.log("month1:", month1List);
  console.log("month2:", month2List);
  console.log("month3:", month3List);

  month1Completed.value = 0;
  month2Completed.value = 0;
  month3Completed.value = 0;
  engagementCompleted.value = 0;

  month1List?.tasks?.forEach((task) => {
    if (task.progresses) task.status = task.progresses[0]?.status;
    if (task.status === "completed" || task.status === "in-review")
      month1Completed.value++;
  });
  month2List?.tasks?.forEach((task) => {
    if (task.progresses) task.status = task.progresses[0]?.status;
    if (task.status === "completed" || task.status === "in-review")
      month2Completed.value++;
  });
  month3List?.tasks?.forEach((task) => {
    if (task.progresses) task.status = task.progresses[0]?.status;
    if (task.status === "completed" || task.status === "in-review")
      month3Completed.value++;
  });
  engagementList.tasks.forEach((task) => {
    if (task.progresses) task.status = task.progresses[0]?.status;
    if (task.status === "completed" || task.status === "in-review")
      engagementCompleted.value++;
  });

  if (month1Completed.value !== month1List.tasks.length) {
    currentMonth.value = "Month 1 Courses";
    monthTasks.value = month1List?.tasks;
    monthCompleted.value = month1Completed.value;
  } else if (month2Completed.value !== month2List?.tasks.length) {
    currentMonth.value = "Month 2 Courses";
    monthTasks.value = month2List?.tasks;
    monthCompleted.value = month2Completed.value;
  } else {
    currentMonth.value = "Month 3 Courses";
    monthTasks.value = month3List?.tasks;
    monthCompleted.value = month3Completed.value;
  }
  console.log("month1Completed.value:", month3Completed.value);
  engagementTasks.value = engagementList.tasks;
  allTasks.value = engagementTasks.value.concat(monthTasks.value);
  engagementPercentDone.value = `${Math.floor(
    (engagementCompleted.value / engagementTasks.value.length) * 100
  )}%`;
  monthPercentDone.value = `${Math.floor(
    (monthCompleted.value / monthTasks.value.length) * 100
  )}%`;
     if (
      currentMonth.value === "Month 3 Courses" &&
      allTasks.value.length ===
        month3Completed.value + engagementCompleted.value
    )
      showAssistanceButton.value = false;
  
};

// monthTasks.value.forEach(task => {
//   if (task.status === 'completed' || task.status === 'in-review') monthCompleted.value++
//   monthPercentDone.value = `${Math.floor(monthCompleted.value / monthTasks.value.length * 100)}%`
// })
// engagementTasks.value.forEach(task => {
//   if (task.status === 'completed' || task.status === 'in-review') engagementCompleted.value++
//   engagementPercentDone.value = `${Math.floor(engagementCompleted.value / engagementTasks.value.length * 100)}%`
// })
// if (monthCompleted.value === monthTasks.value.length && engagementCompleted.value / engagementTasks.value.length) showCompleteModal.value = true
</script>

<style scoped>
CircularProgress {
  margin-bottom: 10px;
}
.progress-area {
  width: 100%;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 120.38%;
  /* or 58px */

  /* leading-trim and text-edge are draft CSS properties.

Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
*/
  display: flex;
  color: #ffffff;
}
.task-columns {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}
.restrict-main {
  width: 935px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  border-radius: 20px;
}
.restrict-button {
  cursor: pointer;
  width: 195px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* HD Orange */
  margin-top: 70px;
  background: #f96302;
  border-radius: 30px;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}
.restrict-explanation {
  width: 506px;
}
.workday {
  height: 140px;
  background: linear-gradient(94.91deg, #afebf5 -2.81%, #cffbce 99.93%);
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  padding: 15px;
  display: flex;
  align-items: center;

  /* dark grey */

  color: #5f6562;
}
.workday-button {
  width: 195px;
  height: 60px;
  background: #f96302;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
}
.bar {
  width: 100%;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 102.1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  margin-left: 20px;
}
.box-title-month {
  height: 100%;
  display: flex;
  border-right: rgba(255, 169, 63, 1) solid 1px;
  justify-content: center;
  align-items: center;
  padding-right: 25px;
  margin-right: 25px;
}
.box-title-engagement {
  height: 100%;
  display: flex;
  border-right: rgba(8, 184, 204, 1) solid 1px;
  align-items: center;
}
.back {
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  width: 165.48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #000000;
}

.submit {
  background: #f96302;
  border-radius: 30px;
  width: 165.48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
}
.bar-outer {
  border-radius: 20px;
  width: 495;
  /* border: 10px solid rgba(255, 255, 255, 0.31); */
  background: rgba(255, 255, 255, 0.31);
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
/* .tasks{
  flex-direction: column;
} */
.task-area {
  display: flex;
  flex-direction: column;
}
.divider {
  width: 100%;
  height: 1px;
  margin-bottom: 45px;
  border: 0;
  background: rgba(201, 204, 206, 0.38);
}

.months-blocks {
  margin-bottom: 40px;
  column-gap: 20px;
}
.assistance-modal {
  position: fixed;
  left: 43.5%;
  bottom: 13%;
  z-index: 1000;
  /* transform: translate(-50%, -50%); */
}

.month-block {
  display: flex;
  align-items: center;
  height: 150px;
  padding: 15px;
  border-radius: 20px;
  background: #dbdede;
  min-width: 400px;
  background: linear-gradient(354.24deg, #f96302 -5.58%, #ff9900 121.2%);
  border-radius: 20px;
}
.engagement-block {
  display: flex;
  align-items: center;
  height: 150px;
  padding: 25px;
  border-radius: 20px;
  background: #dbdede;
  min-width: 400px;
  background: linear-gradient(180deg, #00a1b3 0%, #006873 135.87%);
  border-radius: 20px;
}
.completed {
  margin-top: 10px;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 102.1%;
  /* or 16px */

  display: flex;
  align-items: center;

  color: #ffffff;
}
.task-block {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  /* height: 291px; */
  background: #ffffff;
  border-radius: 20px;
  padding: 40px;
  min-width: 400px;
}
.task {
  width: 313px;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  /* or 53px */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* text-decoration-line: line-through; */

  /* Strikeout */

  color: #908f8f;
}
.back {
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  width: 165.48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #000000;
  margin-right: 10px;
}

.submit {
  background: #f96302;
  border-radius: 30px;
  width: 165.48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
}
.bold {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 750;
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 20px;
  /* identical to box height */
  color: #000000;
}
.button-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.uncheck-modal {
  width: 470px;
  height: 350px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  flex-direction: column;
  padding: 20px 60px 20px 60px;
  left: 43.5%;
  bottom: 33%;
  z-index: 1000;
  border: solid 1px black;
  /* transform: translate(-50%, -50%); */
}
.month-number {
  display: flex;
  width: 100%;
  margin-left: 10px;
}

.month-number-text {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 102.1%;
  /* or 37px */

  display: flex;
  align-items: center;
  letter-spacing: -0.025em;

  color: #ffffff;
}
.circle {
  width: 133px;
  height: 133px;
}
.tasks-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;

  margin: 20px 0;
  row-gap: 40px;
}

.month-tasks-block {
  width: 100%;
  padding: 30px;
  background: white;
  border-radius: 20px;
}

.month-header {
  margin-top: 0;
  font-family: "Helvetica Neue Lt Std", Arial, sans-serif;
  color: #ff8217;
  text-transform: uppercase;
}

.month-tasks-subblock {
  display: flex;
  justify-content: space-between;
  column-gap: 60px;
}

.tasks-block {
  flex: 1;
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
}
.workday {
}
.badging {
  width: 458px;
  height: 230px;
  background: linear-gradient(180deg, #00a1b3 0%, #006873 135.87%);
  border-radius: 20px;
}
.title {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 10px;
  /* HD Orange */

  color: #f96302;
}
</style>
